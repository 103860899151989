import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRepository } from '../repositories/user.repository';
import { IHello } from 'src/app/shared/models/hello.model';
import { IBasePerson } from 'src/app/shared/models/person.model';

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(
    private userRepository: UserRepository
  ) { }

    /**
   * Identifies the current user
   *
   * @returns {Observable<string>} the username being impersonated, or the actually logged in user
   * @memberof UserRepository
   */
    public whoAmI(): Observable<string> {
      return this.userRepository.whoAmI();
    }

  /**
   * Say hello to the current user
   */
  public helloUser(): Observable<IHello> {
    return this.userRepository.getPerson();
  }

  /**
   * Impersonate's username passed in
   *  Api will issue a redirect if successful
   * @param username
   */
  public impersonateUser(username: string): Observable<IHello> {
    return this.userRepository.impersonateUser(username);
  }

  public getUsernameFromLuid(luid: string): Observable<{ value: string }> {
    return this.userRepository.getUsernameFromLuid(luid);
  }

  /**
   * Exit impersonate session
   */
  public stopImpersonating(): Observable<IBasePerson> {
    return this.userRepository.stopImpersonating();
  }

}